<template>
  <div class="student-messages-page">
    <v-row justify="center" no-gutters>
      <v-col md="5">
        <!-- <v-card class="my-2" :elevation="$vuetify.breakpoint.mdAndDown ? 0 : 2"> -->
          <intract-smart-list
            paginated
            disable-search
            :endpoint="reportCardParameterGroupEndpoint"
            ref="parametergroupList"
            @updateGetItemsFunction="(f) => (getItemsFunction = f)"
          >
          <template  v-slot:list-item="{ item:  reportCardParameterGroup }">
            <v-card class="mx-4 my-2" elevation="2">
              <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold primary--text">
                  {{ reportCardParameterGroup.title }}
                </v-list-item-title>
                
                <v-list-item-title class="pt-2 text-wrap" v-for="item in reportCardParameterGroup.group_parameters" :key="item.id">
                  {{ item.title }}
                  <v-icon class="float-right" v-if="groupToEditParameters == reportCardParameterGroup.id" @click="deleteParameterDialogVisible = true;parameterIdToDelete=item.id">mdi-delete</v-icon>
                  <v-icon class="float-right pr-3" v-if="groupToEditParameters == reportCardParameterGroup.id" @click="editParameter(item)">mdi-pencil</v-icon>
                </v-list-item-title>     
                <!-- <v-list-item-subtitle class="pt-4 text-center primary--text">+ Add Parameter</v-list-item-subtitle>            -->
                <v-list-item-subtitle class="text-center">
                  <v-btn :disabled="reportCardParameterGroup.is_used" text color="primary" small @click="addParameter(reportCardParameterGroup.id)">
                    <v-icon>mdi-plus</v-icon>
                    Add Parameter
                  </v-btn>
                  <v-btn text color="blue" small @click="groupToEditParameters = null" v-if="groupToEditParameters == reportCardParameterGroup.id">
                    <v-icon>mdi-check</v-icon>
                    Done
                  </v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- <v-list-item-action> -->
                <v-list-item-icon v-if="groupToEditParameters==null"><intract-drop-down-menu 
                :options-list="getDropdownOptions(reportCardParameterGroup)"
                /></v-list-item-icon>   
              <!-- </v-list-item-action> -->
            </v-list-item>
            </v-card>            
            <!-- <v-divider class="mx-4"></v-divider> -->
          </template>
          </intract-smart-list>
          <v-btn
          color="primary"
          class="non-focused"
          @click="parametergroupCreateSheet.visible = true"
          dark
          bottom
          right
          fixed
          fab
          >
         <v-icon>mdi-plus</v-icon>
          </v-btn>
        <!-- </v-card> -->
      </v-col>
    </v-row>

    <!-- <infinite-loading
      ref="student-messages-list-loader"
      spinner="waveDots"
      :identifier="infiniteIdentifier"
      @infinite="getAllStudents"
    ></infinite-loading> -->
      <intract-create-edit-sheet
        persistent
        v-if="!currentUser.is_student"
        title="Add New Parameter"
        description="This will add a new paramter in the parameter group."
        :visible="parameterCreateSheet.visible"
        @close="parameterCreateSheet.visible = false"
        :fields="parameterformFields"
        :endpoint="reportCardParameterEndpoint"
        :edit-id="parameterCreateSheet.editId"
        :data-object="parameterCreateSheet.obj"
        @objectCreated="parametercreated"
        :create-success-message="parameterCreateSheet.createSuccessMessage"
        @updateObject="(obj) => (parameterCreateSheet.obj = obj)"
        />

        <!-- Edit Parameter Group -->
        <intract-create-edit-sheet
        persistent
        v-if="!currentUser.is_student"
        title="Edit Parameter Group"
        description="Edit Parameter Group"
        :visible="parametergroupEditSheet.visible"
        @close="parametergroupEditSheet.visible = false"
        :fields="parameterGroupEditFormFields"
        :endpoint="reportCardParameterGroupEndpoint"
        :edit-id="parametergroupEditSheet.editId"
        :data-object="parametergroupEditSheet.obj"
        @objectCreated="parametergroupEdited"
        :create-success-message="parametergroupEditSheet.createSuccessMessage"
        :partialUpdate="true"
        @updateObject="(obj) => (parametergroupEditSheet.obj = obj)"
        />

        <!-- Edit Parameter -->
        <intract-create-edit-sheet
        persistent
        v-if="!currentUser.is_student"
        title="Edit Parameter"
        description="Edit Parameter Details"
        :visible="parameterEditSheet.visible"
        @close="parameterEditSheet.visible = false"
        :fields="parameterEditformFields"
        :endpoint="reportCardParameterEndpoint"
        :edit-id="parameterEditSheet.editId"
        :data-object="parameterEditSheet.obj"
        @objectCreated="parameterEdited"
        :create-success-message="parameterEditSheet.createSuccessMessage"
        :partialUpdate="true"
        @updateObject="(obj) => (parameterEditSheet.obj = obj)"
        />

    <intract-create-edit-sheet
    persistent
      v-if="!currentUser.is_student"
      title="Create New Parameter Group"
      description="This will create a new paramter group in the report card"
      :visible="parametergroupCreateSheet.visible"
      @close="parametergroupCreateSheet.visible = false"
      :fields="formFields"
      :endpoint="reportCardParameterGroupEndpoint"
      :edit-id="parametergroupCreateSheet.editId"
      :data-object="parametergroupCreateSheet.obj"
       @objectCreated="parametergroupcreated"
      :create-success-message="parametergroupCreateSheet.createSuccessMessage"
      @updateObject="(obj) => (parametergroupCreateSheet.obj = obj)"
    />

    <confirmation-dialog
     :visible="deleteDialogVisible"
     title="Are you sure you want to Delete this Parameter Group"
     :deleteDialog="true"
     @successCallback="deleteReportCardParameterGroup(groupIdToDelete)"
     persistent
     @failureCallback="deleteDialogVisible = false"
    >

    </confirmation-dialog>

    <confirmation-dialog
     :visible="deleteParameterDialogVisible"
     title="Are you sure you want to Delete this Parameter"
     :deleteDialog="true"
     @successCallback="deleteReportCardParameter(parameterIdToDelete)"
     persistent
     @failureCallback="deleteParameterDialogVisible = false"
    >

    </confirmation-dialog>

    
  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import moment from "moment";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";

export default {
  name: "ReportCardSetup",
  mixins: [Mixins.essentials],
//   props: {
//     room: {
//       type: Object,
//       default: () => null,
//     },
//   },
  components: {
    // InfiniteLoading,
    IntractSmartList,
    IntractCreateEditSheet,
    ConfirmationDialog,
    IntractDropDownMenu,
  },
  data() {
    return {
      getItemsFunction: null,
      moment: moment,
      parametergroupCreateSheet:{
          visible: false,
            createSuccessMessage: "Parameter Group created successfully!",
            editId: null,
            obj: {
                title: "",
                institution: null,
            },
      },
      groupIdToDelete: null,
      parametergroupEditSheet:{
          visible: false,
            createSuccessMessage: "Parameter Group edited successfully!",
            editId: null,
            obj: {
                title: "",
                institution: null,
            },
      },
      parameterEditSheet:{
          visible: false,
            createSuccessMessage: "Parameter edited successfully!",
            editId: null,
            obj: {
                title: "",
                institution: null,
                parameter_group: null,
            },
      },
      parameterCreateSheet:{
          visible: false,
            createSuccessMessage: "Parameter added successfully!",
            editId: null,
            obj: {
                title: "",
                institution: null,
                parameters: [],
            },
      },
      deleteDialogVisible: false,
      groupToEditParameters: null,
      deleteParameterDialogVisible: false,
      parameterIdToDelete: null,
    };
  },
  computed: {
    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Group Name",
          required: true,
          md: 12,
          max: 3000,
        },
        // parameters:{
        //   fieldType: CreateEditFieldTypes.TEXTFIELD,
        //   label: "Parameter Name",
        //   required: true,
        //   md: 12,
        //   max: 3000,
        // },
        //  group: {
        //   fieldType: CreateEditFieldTypes.TEXTFIELD,
        //   label: "Parameter Name",
        //   required: true,
        //   md: 12,
        //   max: 3000,
        // },
      };
    },
    parameterGroupEditFormFields(){
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Group Name",
          required: true,
          md: 12,
          max: 3000,
        }
      }
    },

    parameterEditformFields(){
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Parameter Name",
          required: true,
          md: 12,
          max: 3000,
        }
      }
    },

    parameterformFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Parameter Name",
          required: true,
          md: 12,
          max: 3000,
        },
        //  group: {
        //   fieldType: CreateEditFieldTypes.TEXTFIELD,
        //   label: "Parameter Name",
        //   required: true,
        //   md: 12,
        //   max: 3000,
        // },
      };
    },

    reportCardParameterGroupEndpoint() {
      return this.endpoints.reportCardParameterGroupViewSet;
    },

    reportCardParameterEndpoint() {
      return this.endpoints.reportCardParameterViewSet;
    },
  },
  methods: {
    addParameter(id){
      this.parameterCreateSheet.visible=true;      
      this.parameterCreateSheet.obj.parameter_group = id;
    },

    parametergroupcreated() {
      this.$refs.parametergroupList.getPaginatedItems();
    },
    parametergroupEdited() {
      this.parametergroupEditSheet.obj.title = null;
      this.parametergroupEditSheet.editId = null;
      this.parametergroupEditSheet.obj.institution = null;
      this.parametergroupEditSheet.visible = false;
      this.$refs.parametergroupList.getPaginatedItems();
    },
    parameterEdited() {
      this.parameterEditSheet.obj.title = null;
      this.parameterEditSheet.editId = null;
      this.groupToEditParameters = null;
      this.parameterEditSheet.obj.institution = null;
      this.parameterEditSheet.obj.parameter_group = null;
      this.parameterEditSheet.visible = false;
      this.$refs.parametergroupList.getPaginatedItems();
    },
    parametercreated() {
      this.$refs.parametergroupList.getPaginatedItems();
    },
    async deleteReportCardParameterGroup(groupId) {
      var url = this.endpoints.reportCardParameterGroupViewSet + groupId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.$refs.parametergroupList.getPaginatedItems();
        this.deleteDialogVisible = false;
        this.showSnackbar({
          title: "Parameter Group Deleted",
          // text: err.response.data.message,
          type: "success",
        });
      }
    },

    async deleteReportCardParameter(id) {
      var url = this.endpoints.reportCardParameterViewSet + id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.$refs.parametergroupList.getPaginatedItems();
        this.deleteParameterDialogVisible = false;
        this.parameterIdToDelete = null;
        this.showSnackbar({
          title: "Parameter Deleted",
          // text: err.response.data.message,
          type: "success",
        });
      }
    },

    editParameter(parameter) {
      this.parameterEditSheet.obj.title = parameter.title;
      this.parameterEditSheet.editId = parameter.id;
      this.parameterEditSheet.obj.institution = parameter.institution.id;
      this.parameterEditSheet.obj.parameter_group = parameter.group;
      this.parameterEditSheet.visible = true;
    },


    getDropdownOptions(reportCardParameterGroup) {
      var self = this;
          return [
            {
              value: 1, 
              text: "Edit Group Details", 
              icon: "mdi-pencil",
              type: "edit",
              action() {
                self.parametergroupEditSheet.obj.title = reportCardParameterGroup.title;
                self.parametergroupEditSheet.editId = reportCardParameterGroup.id;
                self.parametergroupEditSheet.obj.institution = self.currentInstitution.id;
                self.parametergroupEditSheet.visible = true;
              }                  
            },
            {
              value: 2,
              text: "Edit Parameters",
              icon: "mdi-pencil",
              type: "edit",
              disabled: reportCardParameterGroup.is_used,
              action() {
                if(reportCardParameterGroup.is_used){
                  self.showSnackbar({
                    title: 'Cannot make changes to this group as reports cards have already been generated using this group.',
                    type: 'warning'
                  })
                }
                else{
                  self.groupToEditParameters = reportCardParameterGroup.id;
                }
              }
            }, 
            {
              value: 3,
              text: "Delete Parameter Group",
              icon: "mdi-delete",
              type: "delete",
              disabled: reportCardParameterGroup.is_used,
              action() {
                if(reportCardParameterGroup.is_used){
                  self.showSnackbar({
                    title: 'Cannot make changes to this group as reports cards have already been generated using this group.',
                    type: 'warning'
                  })
                }
                else{
                  self.deleteDialogVisible = true; 
                  self.groupIdToDelete=reportCardParameterGroup.id
                }
              }          
            }
          ];
    },
  },
  created() {
    // if (this.room) this.getAllStudents();
    this.parametergroupCreateSheet.obj.institution = this.currentInstitution.id;
    this.parameterCreateSheet.obj.institution = this.currentInstitution.id;
  },
//   watch: {
//     room() {
//       console.log("room changed");
//       if (this.room) this.getItemsFunction();
//     },
//   },
};
</script>